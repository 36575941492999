import React from "react"
import styled from "styled-components"
import { getSrc } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { HomeHeader, BannerCenter } from "../utils"
import { colors } from "../utils/styles"
import DishShowcase from "../components/HomePageComponents/DishShowcase"
import { graphql } from "gatsby"

const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 70dvh;
  top: 5dvh;

  @media (min-width: 992px) {
    height: 60vh;
    top: 10vh;
  }
`

const InfoWrapper = styled.article`
  display: grid;
  gap: 0;
  grid-template: 1fr 1fr 1fr / auto;

  @media (min-width: 768px) {
    grid-template: 1fr auto / 1fr 1fr;
  }
`

const InfoBox = styled.div`
  padding: 0.15rem 1rem;
  color: ${colors.mainWhite};
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  border-color: ${colors.secondaryColor};
  border-style: solid;
  border-width: 2px;

  &:first-child {
    border-bottom-width: 0;
  }

  &:last-child {
    border-width: 0;
    padding-top: 0.5rem;
  }

  @media (min-width: 768px) {
    padding: 0.25rem 1.25rem;

    &:first-child {
      border-bottom-width: 2px;
      border-right-width: 0;
    }

    &:last-child {
      grid-column: 1/2;
      translate: 50% 0;
    }
  }
`

const IndexPage = ({ data }) => {
  const smImg = getSrc(data.smImg)
  const img = getSrc(data.img)
  const lgImg = getSrc(data.lgImg)
  const ogImg = getSrc(data.ogImg)

  return (
    <Layout>
      <Seo
        img={ogImg}
        title="Home"
        keywords={[`Restaurant and bar in Kenilworth, Asheville, NC`]}
      />
      <HomeHeader
        smImg={smImg}
        img={img}
        lgImg={lgImg}
        bgPosSm="20% 50%"
        bgPos="25% 50%"
        bgPosLg="35% 50%"
      >
        <ContentWrapper>
          <BannerCenter
            title="Neighborhood Bar & Scratch Kitchen"
            titleProps={{
              style: {
                fontSize: `clamp(1.5rem, 15vw, 3.8rem)`,
              },
            }}
            subtitle={[
              `Southern Fusion Fare,`,
              `Local Drafts, Craft Cocktails & Wine`,
            ]}
          ></BannerCenter>
          <InfoWrapper>
            <InfoBox>Open Wed-Sat 3pm to 9pm</InfoBox>
            <InfoBox>Open Sat-Sun 8am to 2pm</InfoBox>
            <InfoBox>5 Caledonia Rd, Asheville, NC</InfoBox>
          </InfoWrapper>
        </ContentWrapper>
      </HomeHeader>
      <DishShowcase />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    smImg: file(
      relativePath: { eq: "fall-2024/patio-afternoon-food-p-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          formats: [AVIF, WEBP]
          placeholder: BLURRED
          quality: 90
          transformOptions: { cropFocus: ENTROPY, fit: COVER }
          width: 576
        )
      }
    }
    img: file(relativePath: { eq: "fall-2024/patio-afternoon-l-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          formats: [AVIF, WEBP]
          placeholder: BLURRED
          quality: 90
          transformOptions: { cropFocus: ENTROPY, fit: COVER }
          width: 992
        )
      }
    }
    lgImg: file(relativePath: { eq: "fall-2024/patio-afternoon-l-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          formats: [AVIF, WEBP]
          placeholder: BLURRED
          quality: 40
          transformOptions: { cropFocus: ENTROPY, fit: COVER }
          width: 2000
        )
      }
    }
    ogImg: contentfulAsset(title: { eq: "ABK OG Image" }) {
      gatsbyImageData(
        formats: [JPG]
        height: 630
        placeholder: BLURRED
        quality: 75
        width: 1200
      )
    }
  }
`
